<template>
  <div>
    <loading v-if="loading" />
    <no-results
      v-else-if="$_.isEmpty(order)"
      icon="arrow-circle-up"
      title="Upgrade not available"
      message="Your current plan can't be upgraded"
    />
    <div v-else class="columns is-multiline">
      <div class="column is-12">
        <b-message type="is-info">
          <p>
            You're almost there – we just need you to confirm your order.
            <span v-if="order.total > 0">
              Upon proceeding,
              <strong>{{
                $formatCurrency(order.total, order.currency)
              }}</strong>
              will be debited from the {{ cart.paymentMethod.brand }} ending
              {{ cart.paymentMethod.last4 }}.
            </span>
            <span v-else>
              As there is nothing to pay
              <strong>your card will not be charged</strong> on this occasion.
            </span>
          </p>
        </b-message>
      </div>

      <div class="column is-12">
        <apart
          v-for="(summary, index) in summaries"
          :key="`item-summary-${index}`"
          :class="summary.class || ''"
        >
          <span>{{ summary.label }}</span>
          <strong>{{ summary.value }}</strong>
        </apart>
      </div>

      <div class="column is-12">
        <apart
          v-for="(summary, index) in totalsSummary"
          :key="`total-summary-${index}`"
          :class="summary.class || ''"
        >
          <p>
            <span>{{ summary.label }}</span>
            <b-tooltip
              v-if="summary.tooltip"
              :label="summary.tooltip"
              type="is-info"
              multilined
            >
              <b-icon icon="info-circle" size="is-small" type="is-info" />
            </b-tooltip>
          </p>

          <strong>{{ summary.value }}</strong>
        </apart>
      </div>

      <div class="column is-12">
        <b-checkbox
          :disabled="processing"
          @input="$emit('cartdata', { termsAgreed: $event })"
        >
          I have read and agree to Fixed's
          <a
            href="https://fixed.net/knowledge-base/article/terms-and-conditions"
            target="_blank"
            >terms &amp; conditions</a
          >.
        </b-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmUpgrade",
  props: {
    userId: {
      type: String,
      default: ""
    },
    cart: {
      type: Object,
      required: false,
      default: () => {}
    },
    processing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      loadingCoupon: false,
      order: {}
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"]();
    },
    summaries() {
      return this.$_.flatMap(this.order.items, i => {
        return [
          {
            label: i.description,
            value: this.$formatCurrency(i.total, this.order.currency)
          }
        ];
      });
    },
    totalsSummary() {
      const summary = [];
      const { tax } = this.order;
      if (!!tax.jurisdiction || !!tax.total) {
        summary.push({
          label: `Subtotal`,
          value: this.$formatCurrency(this.order.subtotal, this.order.currency)
        });
        summary.push({
          label: `Tax charged at ${tax.rate}%`,
          value: this.$formatCurrency(tax.total, this.order.currency),
          tooltip: `As your card is registered to an address within ${tax.jurisdiction}, ${tax.rate}% VAT has been applied to your order.`
        });
      }
      summary.push({
        label: `Total due today`,
        value: this.$formatCurrency(this.order.total, this.order.currency),
        class: "has-text-info"
      });
      return summary;
    }
  },
  created() {
    this.calculateBasket();
  },
  methods: {
    calculateBasket() {
      this.loading = true;
      return this.$store
        .dispatch("billing/calculateContractUpgrade", {
          contractId: this.cart.contractId,
          couponId: this.cart.couponId,
          paymentMethodId: this.cart.paymentMethod.id,
          productId: this.cart.product._id,
          productTermId: this.cart.productTerm._id
        })
        .then(order => {
          this.order = order;
          this.loading = false;
        })
        .catch(this.onError);
    },
    onError(error) {
      this.loading = false;
      this.loadingCoupon = false;
      this.$toast.open({
        message: error.message,
        type: "is-danger"
      });
    }
  }
};
</script>
